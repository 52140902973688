@import 'variables';

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }

  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }
}

@mixin transition($key, $timing) {
  transition: $key $timing;
  -webkit-transition: $key $timing;
  -moz-transition: $key $timing;
  -ms-transition: $key $timing;
}

@mixin user-select($selection) {
  user-select: $selection;
  -webkit-user-select: $selection;
  -moz-user-select: $selection;
  -ms-user-select: $selection;
}

@mixin display-flex {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-box;
}

@mixin breakpoint-up($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @media screen and (max-width: map-get($grid-breakpoints, $breakpoint)) {
      @content;
    }
  }

  @else {
    @media screen and (max-width: $breakpoint) {
      @content;
    }
  }
}

@mixin breakpoint-down($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @media screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
      @content;
    }
  }

  @else {
    @media screen and (min-width: $breakpoint) {
      @content;
    }
  }
}

@mixin breakpoint-between($max-breakpoint, $min-breakpoint) {
  @media screen and (max-width: $max-breakpoint) and (min-width: $min-breakpoint) {
    @content;
  }
}
