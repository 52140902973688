$grid-breakpoints: (xs: 290px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1440px,
  xxl: 1920px) !default;

$primary-color: #c8102e;
$secondary-color: #9b9b9b;
$success-color: #5cb85c;


$gotham: "Gotham SSm A",
"Gotham SSm B",
Arial,
sans-serif;

$font-types: (h1: 32px,
  h2: 28px,
  h3: 26px,
  h4: 24px,
  h5: 18px,
  h6: 16px,
  p: 14px);

$font-weights: 100,
200,
300,
400,
500,
600,
700,
800,
normal;

$font-sizes: (12: 12px,
  13: 13px,
  14: 14px,
  16: 16px,
  17: 17px,
  18: 18px,
  19: 19px,
  20: 20px,
  21: 21px,
  24: 24px,
  26: 26px,
  28: 28px,
  29: 29px,
  30: 30px,
  32: 32px,
  44: 44px,
  50: 50px,
  64: 64px);

$button-text-color: #fff;
$button-padding: 17px 22px;
$button-font-weight: 800;
$button-font-size: 16px;
$button-border-radius: 10px;
$button-disabled-bg-color: #A4A4A4;

$card-bg-color: $secondary-color;
$card-font-color: #4A4A4A;

$font-color: #4A4A4A;

$avatar-sizes: (sm: calc(164px / 4),
  md: calc(164px / 3),
  lg: 164px,
  xl: 218px);

$line-heights: (1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9)
