@import '../../scss/mixins';
@import '../../scss/variables';

.loading {
  &-overlay {
    text-align: center;
    overflow: hidden;
    z-index: 3000;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }

  &-icon {
    height: 80px;
    width: 80px;
    border-radius: 80px;
    border: 3px solid $primary-color;
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    animation: loader 3s linear infinite;
    -webkit-animation: loader 3s linear infinite;

    &:after {
      content: "";
      position: absolute;
      top: -5px;
      left: 20px;
      width: 11px;
      height: 11px;
      border-radius: 10px;
      background-color: $primary-color;
    }
  }
}

@include keyframes(loader) {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
}
